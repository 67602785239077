import React, { Component } from 'react';
import Layout from '../components/Layout';
import {GoLocation, GoBook} from 'react-icons/go';
import {FaUsers,FaLaptopCode} from 'react-icons/fa';
import {MdQuestionAnswer} from 'react-icons/md';
import {Helmet} from 'react-helmet';
import Img from 'gatsby-image';
import {graphql} from 'gatsby';

class Tutor extends Component {
    render() {
        const { data } = this.props
        return (
            <div className="tutor">
                <Helmet>
                    <title>สอนเขียนโปรแกรม</title>
                </Helmet>
                <Layout>
                    <div className="header-page">
                        <div className="header-page-text">
                            สอนเขียนโปรแกรม
                        </div>
                        <div className="header-page-overay"></div>
                        <Img fluid={data.file.childImageSharp.fluid} alt="สอนเขียนโปรแกรม" className="header-page-img" />
                    </div>
                    <div className="container">
                        <div className="row mt-5">
                            <div className="col-12 text-center">
                                <h3><MdQuestionAnswer className="icon-50" /> ทำไมต้องเขียนโปรแกรม</h3>
                            </div>
                            <div className="col-12">
                                <p className="indent-50 font-size-22">ปัจจุบันนี้เรื่องของเทคโนโลยีเป็นส่วนสำคัญในชีวิตประจำวันของเราทุกคนไปแล้ว ไม่ว่าจะเป็นการเล่น Social ต่างๆ การซื้อของออนไลน์ การดูหนังผ่าน plateform ต่างๆ จะเห็นได้ว่าสิ่งต่างๆเหล่านี้
                                    เข้ามามีผลกับการใช้ชีวิตของเราเกือบจะ 100% แล้ว จะเห็นว่าสิ่งที่เกิดขึ้นนั้นล้วนถูกสร้างมาจากการ coding ทั้งนั้น ทำให้เห็นว่าปัจจุบันการที่เราเขียนโปรแกรมได้นั้นหรือ coding เป็นจะทำให้เรามีงานที่รองรับ
                                    อย่างแน่นอนสำหรับในอนาคต เพราะทุกอย่างที่เป็นเทคโนโลยีล้วนถูกสร้างมาจากการเขียนโปรแกรม
                                </p>
                            </div>
                        </div>
                        <div className="row mt-5">
                            <div className="col-12">
                                <h4><FaUsers className="icon-50" /> เหมาะกับใครบ้าง?</h4>
                            </div>
                            <p className="indent-50 font-size-22">การเขียนโปรแกรมเหมาะสำหรับทุกเพศทุกวัยไม่ว่าจะเป็น นักเรียน นักศึกษา หรือผู้ที่สนในที่จะเรียนรู้ในด้านนี้ก็สามารถที่จะมาเรียนกับเราได้</p>
                        </div>
                        <div className="row mt-5">
                            <div className="col-12">
                                <h4><GoBook className="icon-50" /> รูปแบบการสอน</h4>
                            </div>
                            <div className="col-12">
                                <p className="indent-50 font-size-22">การสอนของเราจะเน้นสอนแบบตัวต่อตัว เป็นกันเอง ทำให้ผู้เรียนสามารถได้รับความรู้ได้อย่างเต็มที่ สงสัยหรือไม่เข้าใจส่วนไหนสามารถสอบถามได้ทันที โดยไม่ต้องเขิลอายที่จะถาม</p>
                            </div>
                        </div>
                        <div className="row mt-5">
                            <div className="col-12">
                                <h4><GoLocation className="icon-50"/> สถานที่สอนและเวลา</h4>
                            </div>
                            <div className="col-12">
                                <p className="indent-50 font-size-22">สถานที่สอนและเวลาสอนขึ้นอยู่กับตกลงกับผู้สอนว่าผู้เรียนสะดวกเรียนที่ไหน เช่น ที่บ้าน ห้างสรรพสินค้า หรือสถานที่อื่นๆ ตามที่ผู้เรียนสะดวกมาเรียนกับเรา ช่วงเวลาไหน วันไหน สามารถติดต่อสอบถามก่อนที่จะเรียนได้เลย</p>
                            </div>
                        </div>
                        <div className="row mt-5">
                            <div className="col-12">
                                <h4><FaLaptopCode className="icon-50"/> สอนอะไรบ้าง</h4>
                            </div>
                            <div className="col-12">
                                <p className="indent-50 font-size-22">
                                    ปัจจุบันการสอนของเราเน้นไปที่การสร้างเว็บไซต์เป็นหลักจึงแบ่งการสอนออกเป็นดังนี้ คือ
                                    <br/>1. ส่วนหน้าเว็บ(Front-End) ภาษาที่ใช้สอนจะมี <b>HTML CSS JavaScript</b>
                                    <br/>2. ส่วนหลังบ้าน(Back-End) ภาษาที่ใช้สอน <b>C#</b> 
                                    <br/>3. ออกแบบฐานข้อมูล(Database) <b>MySql SQL Server</b>
                                    <br/>4. การทำเว็บไซต์ด้วย <b>ASP.NET หรือ ASP.NET Core (C#)</b>
                                    <br/><label className="text-danger">**ผู้เรียนสามารถเลือกเรียนตามที่ผู้เรียนสนใจได้เลย โดยการสอนจะขึ้นอยู่กับผู้เรียนเลยว่าผู้เรียนอยากรู้ส่วนไหนบ้าง</label>
                                </p>
                            </div>
                        </div>
                    </div>
                </Layout>
            </div>
        );
    }
}

export default Tutor;

export const query = graphql`
query{
      file(relativePath: {eq: "tutor.jpg"}) {
        childImageSharp {
          fluid{
            ...GatsbyImageSharpFluid
          }
        }
      }
  }  
`
